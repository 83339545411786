<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <b-row>
          <b-avatar
            src="https://placekitten.com/300/300"
            :text="avatarText('M')"
            size="104px"
            rounded
          />
          <b-row class="flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
          </b-row>
        </b-row>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Primary Masjed</span>
            </th>
            <td class="pb-50 text-capitalize">
              MuslimDo
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-50 text-capitalize">
              Goldsboro
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              United State
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.phone_number }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import router from '@/router'

export default {
  data() {
    return {
      userData: null,
    }
  },
  created() {
    this.$http.get(`http://users-microsevice.muslimdo.com/api/internalops/user/${router.currentRoute.params.id}`).then(res => {
      this.userData = res.data.data
    })
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>

</style>
